<template>
  <div class="custom-image">
    <img class="bg" :src="image" alt="" />
    <div
      class="custom-image__btns d-flex align-center justify-center px-2 py-1"
    >
      <img
        class="mx-1 cursor-pointer"
        width="30px"
        height="25px"
        :src="require('@/assets/images/icons/eye.svg')"
        alt=""
        @click="handleView"
      />
      <img
        class="mx-1 cursor-pointer"
        width="30px"
        height="25px"
        :src="require('@/assets/images/icons/delete.svg')"
        alt=""
        @click="$emit('delete')"
      />
    </div>

    <!-- preview image -->
    <v-dialog
      v-model="imagePreview"
      width="600"
      content-class="pa-10 no-shadow"
    >
      <div class="preview-dialog">
        <v-icon
          @click="imagePreview = false"
          class="close-preview cursor-pointer"
          color="white"
          >mdi-close</v-icon
        >

        <img
          width="100%"
          height="400px"
          class="object-cover"
          :src="image"
          alt=""
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    imagePreview: false,
  }),
  methods: {
    handleView() {
      this.imagePreview = true
    },
  },
};
</script>

<style lang="scss">
.custom-image {
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  .bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__btns {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    background: white;
  }
}
</style>
