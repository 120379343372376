<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div class="mt-5">
      <v-form v-model="valid" ref="stepForm">
        <v-tabs
          v-model="selectedDay"
          centered
          class="mb-5"
          @change="dayChanged"
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>

          <v-tab v-for="(day, i) in dayValue" :key="i"> Day {{ i + 1 }} </v-tab>
        </v-tabs>
        <!-- daysj -->
        <v-tabs-items v-model="selectedDay">
          <v-tab-item eager v-for="(day, i) in dayValue" :key="i">
            <!-- map -->
            <div class="google-map-step">
              <GmapMap
                :center="day.mapLocation"
                :zoom="9"
                style="width: 100%; height: 250px"
                @click="addMapOnClick($event, i)"
              >
                <GmapMarker
                  :key="keyMarkChange + i"
                  v-for="(mark, i) in day.obj?.schedules || []"
                  :position="{
                    lat: Number(mark.latitude),
                    lng: Number(mark.longitude)
                  }"
                  :icon="{
                    url: require(`@/assets/images/map/num${i + 1}.png`)
                  }"
                ></GmapMarker>
              </GmapMap>
            </div>
            <div>
              <div class="py-3 d-flex align-center justify-start">
                <!-- steps -->
                <div
                  class="secondary align-center d-flex justify-center mr-3 cursor-pointer"
                  style="width: 30px; height: 30px; border-radius: 50%"
                  v-for="(mapStep, j) in day.obj?.schedules || []"
                  :key="j"
                  :class="day.selectedMapStepIndex == j ? 'lighten-2' : ''"
                  @click="MapStepView(mapStep, j, i)"
                >
                  <span class="white--text font-13 font-500">{{ j + 1 }}</span>
                </div>

                <!-- add new step -->
                <div
                  class="secondary align-center d-flex justify-center mx-2 cursor-pointer"
                  style="width: 30px; height: 30px; border-radius: 50%"
                  @click="newMapStepHandler(i)"
                >
                  <span class="white--text font-13 font-500"
                    ><v-icon color="white" size="18">mdi-plus</v-icon></span
                  >
                </div>
              </div>
              <div v-if="day.selectedMapStep">
                <v-row class="pt-3">
                  <!-- Transportation -->
                  <v-col cols="12" md="" class="pt-1 pb-0">
                    <label class="grey--text font-12 font-500"
                      >Transportation</label
                    >
                    <base-select
                      :items="transportList"
                      v-model="day.selectedMapStep.transport"
                      placeholder="Select transportation"
                      itemValue="value"
                      :key="selectedDay"
                    ></base-select>
                  </v-col>

                  <!-- location -->
                  <v-col cols="12" md="6" class="pt-1 pb-0">
                    <label class="grey--text font-12 font-500">Location</label>
                    <GmapAutocomplete
                      class="place-input"
                      ref="gMapAutocompleteSteps"
                      :select-first-on-enter="true"
                      placeholder="Add address"
                      @place_changed="setPlace"
                    />
                  </v-col>

                  <!-- activity name -->
                  <v-col cols="12" class="pt-1 pb-0">
                    <label class="grey--text font-12 font-500"
                      >Activity Name</label
                    >
                    <base-input
                      sizeWord="60"
                      placeholder="Add a short, clear description"
                      v-model="day.selectedMapStep.title[language]"
                    ></base-input>
                  </v-col>

                  <!-- description -->
                  <v-col cols="12" md="12" class="pt-1 pb-0 mt-3">
                    <label class="grey--text font-12 font-500"
                      >DESCRIPTION</label
                    >
                    <base-textarea
                      sizeWord="250"
                      placeholder="What is this step about? Tell us more juicy details."
                      v-model="day.selectedMapStep.description[language]"
                    ></base-textarea>
                  </v-col>
                  <!-- start TIME -->
                  <v-col cols="9" md="6">
                    <span class="grey--text font-12 font-500 d-block"
                      >Start Time</span
                    >
                    <div class="d-flex align-center justify-space-between">
                      <input
                        class="ml-2 font-20 align-center"
                        style="
                          border: 0.5px solid rgb(219, 216, 216);
                          border-radius: 2px;
                        "
                        type="time"
                        v-model="day.selectedMapStep.startTime"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- upload photos -->
                <div
                  class="collection-form-step-content mt-8 mb-6"
                  :key="`${filesUploaded}-${day.selectedMapStep}`"
                >
                  <div :key="filesUploaded" v-if="day">
                    <div
                      v-for="(file, i) in day.selectedMapStep.image"
                      :key="i"
                    >
                      <div
                        class="upload-item d-flex align-center justify-space-between px-6 py-3 mb-2 whitebg"
                      >
                        <div @click="previewHandler(file)">
                          <v-icon color="grayicon"
                            >mdi-folder-multiple-image</v-icon
                          >
                          <span
                            class="font-14 black--text d-inline-block ml-2"
                            >{{
                              file.fileName ||
                              file.name ||
                              (file.mediaId ? file.mediaId.url : "")
                            }}</span
                          >
                        </div>
                        <v-icon
                          class="upload-item-delete"
                          size="16"
                          @click="deleteFile(i)"
                          >mdi-close</v-icon
                        >
                      </div>
                    </div>
                  </div>

                  <!-- upload new -->
                  <div class="upload-item px-6 py-3">
                    <div>
                      <v-icon color="grayicon"
                        >mdi-folder-multiple-image</v-icon
                      >
                      <span class="font-14 black--text d-inline-block ml-2"
                        >Upload Image</span
                      >
                    </div>
                    <v-file-input
                      :key="`${filesUploaded}-${selectedDay}`"
                      prepend-icon=""
                      @change="handleUpload($event)"
                      height="50"
                      hide-details="true"
                      accept="image/*"
                      multiple
                    ></v-file-input>
                  </div>
                </div>
              </div>
              <div
                class="pb-3 cursor-pointer d-flex align-center justify-space-between"
                v-if="
                  day.obj && day.obj.schedules && day.obj.schedules.length > 0
                "
              >
                <v-checkbox
                  v-model="day.selectedMapStep.guided"
                  :hide-details="true"
                  :label="
                    'This step is ' + selectedMapStep.guided
                      ? 'guided'
                      : 'unguided'
                  "
                ></v-checkbox>
                <div @click="deleteMapStep(i)">
                  <v-icon color="error">mdi-delete</v-icon>
                  <span class="error--text">Delete this step</span>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <v-col cols="12">
          <div class="b-bottom my-1"></div>
        </v-col>

        <!-- **** Stats **** -->
        <v-col cols="12" class="mb-0 pt-0 pb-0">
          <span class="font-26 font-500 d-block">Good to know</span>
        </v-col>

        <!-- what to tabs -->
        <v-col cols="12" class="px-6">
          <div class="mt-4" v-for="(whatTo, i) in goodToKnow" :key="i">
            <span class="grey--text font-12 font-500 d-block mb-2">{{
              whatTo.title
            }}</span>
            <v-row v-for="(desc, j) in whatTo.desc" :key="j">
              <!-- delete -->
              <v-col cols="1" class="py-1">
                <div class="base-btn" @click="removeWhatToHandler(i, j)">
                  <v-icon color="error">mdi-minus-circle-outline</v-icon>
                </div>
              </v-col>

              <!-- text -->
              <v-col cols="11" class="py-1">
                <base-input
                  placeholder="Add a short headline"
                  :rules="[]"
                  v-model="desc.value[language]"
                ></base-input>
              </v-col>
            </v-row>

            <!-- add new -->
            <div class="py-1 mt-2">
              <div class="base-btn" @click="addWhatToHandler(i)">
                <v-icon color="grey">mdi-plus-circle-outline</v-icon>
              </div>
            </div>
          </div>
        </v-col>
      </v-form>
    </div>
    <div class="mt-14">
      <buttons-experience
        @cancel="$emit('cancelHandler')"
        @next="nextHandler"
        cancelText="Back"
      ></buttons-experience>
    </div>

    <!-- preview image -->
    <v-dialog
      v-model="imagePreview"
      width="600"
      content-class="pa-10 no-shadow"
    >
      <div class="preview-dialog">
        <v-icon
          @click="imagePreview = false"
          class="close-preview cursor-pointer"
          color="white"
          >mdi-close</v-icon
        >

        <img
          width="100%"
          height="400px"
          class="object-cover"
          :src="imagePreviewUrl"
          alt=""
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    },
    step: {
      type: Number,
      default: 0
    },
    daysChanged: {
      type: Boolean,
      default: false
    },
    days: {
      type: [Array, Object],
      default: () => {}
    }
  },
  components: {
    ButtonsExperience
    // StepSix,
  },
  data: () => ({
    name: "",
    valid: false,
    obj: {
      schedules: [{ image: [], lat: 32.21212, lng: 32.121212 }]
    },
    goodToKnow: [
      { title: "What To Bring", desc: [{ value: {ar: "", en: ""} }] },
      { title: "What To Wear", desc: [{ value: {ar: "", en: ""} }] },
      { title: "What To Expect", desc: [{ value: {ar: "", en: ""} }] }
    ],

    // map steps
    mapLocation: { lat: 33.8547, lng: 35.8623 },
    selectedMapStep: { image: [] },
    selectedMapStepIndex: 0,
    stepMapLink: "",
    transportList: [
      { title: "Walking", value: "walk" },
      { title: "Drive", value: "drive" },
      { title: "Swim", value: "swim" },
      { title: "Sleep", value: "sleep" },
      { title: "Fly", value: "fly" },
      { title: "Eat", value: "eat" }
    ],

    filesUploaded: false,
    keyMarkChange: false,
    keyChanged: false,

    imagePreview: false,
    imagePreviewUrl: "",
    selectedDayIndex: 0,
    selectedDay: "",

    maxHour: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 24 || ""
    ],

    maxMin: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 60 || ""
    ],
    dayValue: [{}],
    language: "en"
  }),
  methods: {
    dayChanged(val) {
      this.dayValue[val].selectedMapStepIndex =
        this.dayValue[val].obj.schedules.length - 1;

      this.dayValue[val].selectedMapStep =
        this.dayValue[val].obj.schedules[
          this.dayValue[val].obj.schedules.length - 1
        ];
      this.selectedDayIndex = val;
      this.$refs.gMapAutocompleteSteps[val].$el.value =
        this.dayValue[val].selectedMapStep.location || "";
    },
    setPlace(event) {
      this.dayValue[this.selectedDay].selectedMapStep.location =
        this.$refs.gMapAutocompleteSteps[this.selectedDay].$el.value;
      if (event.geometry && event.geometry.location) {
        this.dayValue[this.selectedDay].selectedMapStep.longitude =
          event.geometry.location.lng();
        this.dayValue[this.selectedDay].selectedMapStep.latitude =
          event.geometry.location.lat();
      }
    },
    handleUpload(files) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        if (!this.dayValue[this.selectedDay].selectedMapStep.image) {
          this.dayValue[this.selectedDay].selectedMapStep.image = [];
        }
        this.dayValue[this.selectedDay].selectedMapStep.image.push(file);
      });
      this.filesUploaded = !this.filesUploaded;
    },

    deleteFile(i) {
      this.dayValue[this.selectedDay].obj.schedules[
        this.selectedMapStepIndex
      ].image = this.dayValue[this.selectedDay].obj.schedules[
        this.selectedMapStepIndex
      ].image.filter((file, idx) => idx != i);
      this.filesUploaded = !this.filesUploaded;
    },
    newMapStepHandler(i) {
      this.dayValue[i].obj.schedules.push({
        latitude: 33.8547,
        longitude: 35.8623,
        guided: false
      });
      this.dayValue[i].selectedMapStepIndex =
        this.dayValue[i].obj.schedules.length - 1;
      this.dayValue[i].selectedMapStep =
        this.dayValue[i].obj.schedules[
          this.dayValue[i].obj.schedules.length - 1
        ];
      this.$refs.gMapAutocompleteSteps[i].$el.value =
        this.dayValue[i].selectedMapStep.location || "";
    },
    deleteMapStep(i) {
      this.dayValue[i].obj.schedules = [
        ...this.dayValue[i].obj.schedules.filter(
          (step, idx) => idx != this.dayValue[i].selectedMapStepIndex
        )
      ];
      this.dayValue[i].selectedMapStepIndex = 0;
      if (this.dayValue[i].obj.schedules.length == 0) {
        this.dayValue[i].selectedMapStep = {
          title: {},
          description: {},
          value: {},
          images: []
        };
      } else {
        this.dayValue[i].selectedMapStep = this.dayValue[i].obj.schedules[0];
      }
      this.$refs.gMapAutocompleteSteps[i].$el.value =
        this.dayValue[i].selectedMapStep.location || "";
    },
    MapStepView(mapStep, j, i) {
      this.dayValue[i].mapLocation.lng = mapStep.longitude;
      this.dayValue[i].mapLocation.lat = mapStep.latitude;
      this.dayValue[i].selectedMapStep = mapStep;
      this.dayValue[i].selectedMapStepIndex = j;
      this.$refs.gMapAutocompleteSteps[i].$el.value =
        this.dayValue[i].selectedMapStep.location || "";
    },
    addMapLocation() {
      let newLocation;
      if (this.stepMapLink) {
        newLocation = this.regExLatLng(this.stepMapLink);
        if (newLocation.lat) {
          this.mapLocation = newLocation;
          this.obj.schedules[this.selectedMapStepIndex].longitude =
            newLocation.lng;
          this.obj.schedules[this.selectedMapStepIndex].latitude =
            newLocation.lat;
        } else {
          return;
        }
      } else {
        return;
      }
    },
    addMapOnClick(event, i) {
      this.dayValue[i].obj.schedules[
        this.dayValue[i].selectedMapStepIndex
      ].longitude = event.latLng.lng();
      this.dayValue[i].obj.schedules[
        this.dayValue[i].selectedMapStepIndex
      ].latitude = event.latLng.lat();
      this.keyMarkChange = !this.keyMarkChange;
    },
    regExLatLng(link) {
      const regex = new RegExp("@(.*),(.*),");
      const lng_lat_match = link.match(regex);

      if (lng_lat_match && lng_lat_match.length !== 0) {
        return { lng: +lng_lat_match[2], lat: +lng_lat_match[1] };
      }

      return null;
    },
    // preview image
    previewHandler(file) {
      if (this.objItem._id) {
        this.imagePreview = true;
        if (file._id) this.imagePreviewUrl = file.mediaId.url;
        if (file.name) this.imagePreviewUrl = URL.createObjectURL(file);
      } else {
        this.imagePreview = true;
        this.imagePreviewUrl = URL.createObjectURL(file);
      }
    },
    addWhatToHandler(i) {
      this.goodToKnow[i].desc.push({ value: {} });
    },
    removeWhatToHandler(i, j) {
      this.goodToKnow[i].desc = [
        ...this.goodToKnow[i].desc.filter((_, idx) => idx != j)
      ];
    },
    async nextHandler() {
      let locations = this.dayValue.map(
        (day) =>
          day.obj.schedules
            .map((schedule) => schedule.location)
            .filter((item) => item == undefined).length
      );
      let locationsAreValid = !locations.some((item) => item !== 0);

      if (!locationsAreValid) {
        this.$store.dispatch("showSnack", {
          text: "some locations are missing",
          color: "error"
        });
        setTimeout(() => {
          document.querySelector(".v-input.error--text:first-of-type");
        }, 100);
        return;
      }
      this.$refs.stepForm.validate();
      if (!this.valid) {
        setTimeout(() => {
          document.querySelector(".v-input.error--text:first-of-type");
        }, 100);
        return;
      }

      this.obj.bring = this.goodToKnow[0].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.wear = this.goodToKnow[1].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.expect = this.goodToKnow[2].desc.map((val) => {
        return { label: val.value };
      });

      let scheduleArr = [];
      this.dayValue.forEach((day, idx) => {
        if (day.obj.schedules.length == 1) {
          scheduleArr.push({
            day: {
              dayNumber: idx + 1,
              schedule: [day.selectedMapStep]
            }
          });
        } else {
          scheduleArr.push({
            day: {
              dayNumber: idx + 1,
              schedule: [...day.obj.schedules]
            }
          });
        }
      });

      scheduleArr.forEach((day) => {
        day.day.schedule.forEach((sch) => {
          if (sch.attraction && sch.attraction._id) {
            sch.attraction = sch.attraction._id;
          }
          if (sch.transport && sch.transport.value) {
            sch.transport = sch.transport.value;
          }
          sch.startTime = this.$convertTo12Hour(sch.startTime);
        });
      });
      this.obj.newSchedules = scheduleArr;
      this.$emit("nextHandler", this.obj);
    },

    fetchItemByID() {
      this.goodToKnow[0].desc = this.objItem.bring.map((val) => {
        return { value: val.label, _id: val._d };
      });
      this.goodToKnow[1].desc = this.objItem.wear.map((val) => {
        return { value: val.label, _id: val._d };
      });
      this.goodToKnow[2].desc = this.objItem.expect.map((val) => {
        return { value: val.label, _id: val._d };
      });
      // set schedules
      if (this.objItem.duration == 1) {
        this.dayValue = [];
        this.objItem.newSchedules.forEach((day, i) => {
          if (i == 0) {
            this.dayValue.push({
              obj: {
                schedules: day.day.schedule
              },
              mapLocation: { lat: 33.8547, lng: 35.8623 },
              selectedMapStep: day.day.schedule[0],
              selectedMapStepIndex: 0
            });
          }
        });
      } else if (
        this.objItem.newSchedules.length > 0 &&
        this.dayValue.length == this.objItem.duration &&
        this.dayValue.length == this.objItem.newSchedules.length &&
        !this.daysChanged
      ) {
        this.dayValue = [];
        this.objItem.newSchedules.forEach((day) => {
          this.dayValue.push({
            obj: {
              schedules: day.day.schedule
            },
            mapLocation: { lat: 33.8547, lng: 35.8623 },
            selectedMapStep: day.day.schedule[0],
            selectedMapStepIndex: 0
          });
        });
        // if day duration number < newSechule days
      } else if (this.dayValue.length < this.objItem.newSchedules.length) {
        let newDayValue = [];
        this.objItem.newSchedules.forEach((day, i) => {
          if (i < this.dayValue.length) {
            newDayValue.push({
              obj: {
                schedules: day.day.schedule
              },
              mapLocation: { lat: 33.8547, lng: 35.8623 },
              selectedMapStep: day.day.schedule[0],
              selectedMapStepIndex: 0
            });
          }
        });
        this.dayValue = newDayValue;
      } else {
        let newDayValue = [];
        this.objItem.newSchedules.forEach((day) => {
          newDayValue.push({
            obj: {
              schedules: day.day.schedule
            },
            mapLocation: { lat: 33.8547, lng: 35.8623 },
            selectedMapStep: day.day.schedule[0],
            selectedMapStepIndex: 0
          });
        });
        // newDayValue.selectedMapStep = this.dayValue[0].obj.schedules[0];
        this.dayValue.forEach((day, i) => {
          if (i > this.objItem.newSchedules.length - 1) {
            newDayValue.push({
              obj: {
                schedules: [
                  { lat: 32.21212, lng: 32.121212, guided: false, image: [] }
                ]
              },
              mapLocation: { lat: 33.8547, lng: 35.8623 },
              selectedMapStep: day.day.schedule[0],
              selectedMapStepIndex: 0
            });
          }
        });
        this.dayValue = newDayValue;
      }

      this.dayValue.forEach((day) => {
        day.obj.schedules.forEach((sch) => {
          if (sch.startTime) {
            sch.startTime = this.$convertTo24Hour(sch.startTime);
          }
        });
      });
    },
    initialize() {
      for (let index = 0; index < this.dayValue.length; index++) {
        this.dayValue[index].selectedMapStepIndex =
          this.dayValue[index].obj.schedules.length - 1;

        this.dayValue[index].selectedMapStep =
          this.dayValue[index].obj.schedules[
            this.dayValue[index].obj.schedules.length - 1
          ];
        this.$refs.gMapAutocompleteSteps[index].$el.value =
          this.dayValue[index].selectedMapStep.location || "";

        this.$refs.gMapAutocompleteSteps[index].$el.value =
          this.dayValue[index].selectedMapStep.location || "";
        var input = document.getElementById("mapInput");
        var autocomplete = new window.google.maps.places.Autocomplete(input);
        window.google.maps.event.addListener(
          autocomplete,
          "place_changed",
          () => {
            var place = autocomplete.getPlace();
            let lat = place.geometry.location.lat();
            let lng = place.geometry.location.lng();
            this.dayValue[index].mapLocation.lat = lat;
            this.dayValue[index].mapLocation.long = lng;
          }
        );
      }
    }
  },
  async created() {
    this.dayValue = this.days;
    this.language = this.$root.language;
    if (this.objItem && this.objItem._id) {
      await this.fetchItemByID();
    } else {
      this.dayValue = this.dayValue.map(() => {
        return {
          obj: {
            schedules: [
              { lat: 32.21212, lng: 32.121212, guided: false, image: [], title: {}, description: {} }
            ]
          },
          mapLocation: { lat: 33.8547, lng: 35.8623 },
          selectedMapStep: { title: {}, description: {} },
          selectedMapStepIndex: 0
        };
      });
      this.dayValue[0].selectedMapStep = this.dayValue[0].obj.schedules[0];
    }
    await this.initialize();
  }
};
</script>
