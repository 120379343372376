<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <v-form v-model="valid" @submit.prevent="submitForm" ref="form">
      <!-- upload image -->
      <div class="mt-10">
        <v-row>
          <v-col cols="12" class="py-1 pb-2">
            <div class="upload-image">
              <img :src="newCoverImage ? newCoverImage : obj.coverImage.url" />
              <div class="upload-btn cursor-pointer">
                <v-icon dark color="grey">mdi-upload</v-icon>
                <span class="font-15 grey--text">Upload Image</span>
                <v-file-input
                  @change="handleCoverImage"
                  accept="image/*"
                  label="File input"
                  class="file-upload"
                  hide-details="true"
                ></v-file-input>
              </div>
            </div>
          </v-col>

          <!-- New Hosts -->
          <v-col cols="12" class="py-1">
            <label class="grey--text font-12 font-500">Host</label>
            <base-select
              v-model="obj.hostId"
              :items="hosts"
              itemText="fullName"
              itemValue="_id"
              placeholder="Select Host"
            >
            </base-select>
          </v-col>
          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>

          <!-- **** Main Info **** -->
          <v-col cols="12" class="mb-0 pt-0">
            <span class="font-26 font-500 d-block mb-5">Main Info</span>
            <v-row class="px-8">
              <!-- Experience  Name -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500"
                  >Experience Name</label
                >
                <base-input
                  v-model="obj.title[language]"
                  placeholder="Experience  Name"
                >
                </base-input>
              </v-col>

              <!-- Switch -->
              <v-col cols="12" md="6" class="py-1">
                <v-switch
                  v-model="obj.tag"
                  inset
                  :label="obj.tag ? 'Exclusive' : 'Curated'"
                ></v-switch>
              </v-col>

              <!-- District -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500">District</label>
                <base-select
                  v-model="obj.district"
                  :items="districts"
                  placeholder="District"
                  itemText="name"
                  itemValue="_id"
                ></base-select>
              </v-col>

              <!-- Location -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500">Main Location</label>
                <GmapAutocomplete
                  class="place-input"
                  ref="gMapAutocomplete"
                  :select-first-on-enter="true"
                  placeholder="Main Location"
                  @place_changed="setPlace"
                />
              </v-col>

              <!-- Season -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500">Season</label>
                <base-select
                  v-model="obj.season"
                  :items="seasons"
                  placeholder="Season"
                  itemText="name"
                  itemValue="_id"
                ></base-select>
              </v-col>

              <!-- New Category -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500">New Category</label>
                <base-select
                  v-model="obj.newCategory"
                  :items="categories"
                  itemText="name"
                  @input="handleCategory"
                  itemValue="_id"
                  placeholder="New Category"
                >
                </base-select>
              </v-col>

              <!-- DIFFICULTY -->
              <v-col cols="12" class="py-1">
                <label class="grey--text font-12 font-500">DIFFICULTY</label>
                <base-select
                  v-model="obj.difficulty"
                  :items="difficulty"
                  itemText="name"
                  itemValue="_id"
                  placeholder="DIFFICULTY"
                  :rules="[(v) => !!v || v >= 0 || '']"
                >
                </base-select>
              </v-col>

              <!-- friendly -->
              <v-col cols="12" md="3">
                <v-checkbox v-model="obj.pet" label="pet friendly"></v-checkbox>
              </v-col>

              <!-- family -->
              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="obj.familyFriendly"
                  label="family friendly"
                ></v-checkbox>
              </v-col>

              <!-- private -->
              <v-col cols="12" md="3">
                <v-checkbox v-model="obj.private" label="private"></v-checkbox>
              </v-col>

              <!-- anytime -->
              <v-col cols="12" md="3">
                <v-checkbox v-model="obj.anytime" label="anytime"></v-checkbox>
              </v-col>

              <!-- Title -->
              <v-col cols="12">
                <label class="grey--text font-12 font-500">Title</label>
                <base-textarea
                  :rules="[(v) => !!v || 'this field is require']"
                  v-model="obj.header[language]"
                  placeholder="What is this event about? Tell us more juicy details."
                ></base-textarea>
              </v-col>

              <!-- description -->
              <v-col cols="12">
                <label class="grey--text font-12 font-500">DESCRIPTION</label>
                <base-textarea
                  :rules="[(v) => !!v || 'this field is require']"
                  v-model="obj.body[language]"
                  placeholder="What is this event about? Tell us more juicy details."
                ></base-textarea>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>

          <!-- **** Stats **** -->
          <v-col cols="12" class="mb-0 pt-0 pb-0">
            <span class="font-26 font-500 d-block">Stats</span>
          </v-col>
          <v-col cols="12" class="px-6">
            <span class="font-18" v-if="stats.length == 0"
              >Select Category To See Stats</span
            >
            <v-row
              class="px-3 whitebg"
              :class="stats.length == 0 ? '' : 'py-2'"
            >
              <v-col
                cols="12"
                md="6"
                class="py-2"
                v-for="(stat, i) in stats"
                :key="i"
              >
                <v-checkbox
                  color="darkblue"
                  hide-details="true"
                  v-model="stat.checked"
                  :label="stat.name"
                  @change="stateChange(stat)"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" v-for="(stat, i) in statInputs" :key="i">
                <span class="grey--text font-12 font-500">{{ stat.name }}</span>
                <base-input
                  v-if="stat.checked && stat.unit"
                  v-model="stat.value"
                  :placeholder="stat.name"
                ></base-input>
                <base-select
                  v-if="stat.checked && !stat.unit"
                  :items="stat.selections"
                  itemText="value"
                  itemValue="_id"
                  v-model="stat.value"
                  :placeholder="stat.name"
                ></base-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>

          <!-- **** Images & Media **** -->
          <main-images
            :images="obj.images.map((image) => image.mediaId || image)"
            @handleUpload="handleUpload"
            @delete="handleDelete"
          ></main-images>

          <v-col cols="12">
            <span class="font-26 font-500 d-block">Timing & Availability</span>
          </v-col>

          <!-- Duration -->
          <v-col cols="12" class="my-0 py-0">
            <span class="grey--text font-12 font-500 d-block">Durations</span>
          </v-col>
          <v-col cols="6" class="pt-1">
            <base-input
              placeholder="Durations"
              type="number"
              v-model="obj.duration"
              :rules="[(v) => !!v || v >= 0 || '']"
            ></base-input>
          </v-col>
          <v-col cols="6" class="py-1">
            <base-select
              :items="dayOrHour"
              v-model="obj.dayOrHour"
              itemText="name"
              itemValue="_id"
              placeholder="Hour, day"
              :key="obj.dayOrHour"
              :rules="[(v) => !!v || v >= 0 || '']"
            ></base-select>
          </v-col>

          <v-col cols="12" class="mt-14">
            <!-- buttons -->
            <buttons-experience
              @cancel="$emit('cancelHandler')"
              @next="handleNext"
            ></buttons-experience>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
import MainImages from "./main/Images.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience,
    MainImages
  },
  data: () => ({
    switch1: false,
    name: "",
    newCoverImage: "",
    stats: [],
    categories: [],
    districts: [],
    seasons: [
      { name: "All", _id: "all" },
      { name: "Summer", _id: "summer" },
      { name: "Winter", _id: "winter" }
    ],
    difficulty: [
      { name: "Easy", _id: 0 },
      { name: "Medium", _id: 1 },
      { name: "Hard", _id: 2 }
    ],
    countries: [{ name: "Lebanon", _id: "LB" }],
    valid: false,
    datetime: new Date(),
    obj: {
      title: { en: undefined, ar: undefined },
      coverImage: {},
      country: "LB",
      stats: [],
      pet: false,
      familyFriendly: false,
      private: false,
      anytime: false,
      images: [],
      dayOrHour: 0,
      header: { en: undefined, ar: undefined },
      body: { en: undefined, ar: undefined }
    },
    files: [],
    filesUploaded: false,

    imagePreview: false,
    imagePreviewUrl: "",

    isEdit: false,
    keyChanged: false,
    keyMarkChange: false,

    hosts: [],
    statInputs: [],

    dayOrHour: [
      { name: "Day", _id: 0 },
      { name: "Hour", _id: 1 }
    ],

    language: "en"
  }),

  watch: {
    "$route.params": {
      async handler() {
        await this.fetchHosts();
        await this.fetchCategories();
      }
    }
  },
  methods: {
    handleCoverImage(file) {
      this.newCoverImage = URL.createObjectURL(file);
      this.obj.coverImage = file;
      if (this.objItem) {
        this.obj.newCoverImage = URL.createObjectURL(file);
      }
    },
    async handleCategory(event) {
      this.categories.forEach((cat) => {
        if (cat._id == event) {
          this.obj.event = cat.name;
        }
      });
      await this.fetchStats(event);
    },
    handleUpload(files) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        if (!this.obj.images) {
          this.obj.images = [];
        }
        this.obj.images.push({ file: file, url: URL.createObjectURL(file) });
      });
    },

    handleDelete(i) {
      this.obj.images = [...this.obj.images.filter((_, idx) => idx != i)];
    },
    async fetchCategories() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.categories = data.categories;
        this.districts = data.districts;
      }
    },
    async fetchStats(val) {
      let { data } = await this.$axios.get(`activityStats/category/${val._id}`);
      if (data) {
        this.stats = data.stats;
        this.stats = this.objItem
          ? [
              ...data.stats.map((stat) => {
                let objStat = this.obj.stats.filter((s) => {
                  if (s.statType) {
                    return s.statType._id == stat._id;
                  } else {
                    return s._id == stat._id;
                  }
                });
                if (objStat.length > 0) {
                  return {
                    ...stat,
                    value: objStat[0].value,
                    checked: true,
                    statType: stat._id
                  };
                } else {
                  return { ...stat, checked: false, statType: stat._id };
                }
              })
            ]
          : [
              ...data.stats.map((stat) => {
                return { ...stat, checked: false };
              })
            ];
      }
    },
    async fetchItemByID() {
      this.obj = this.objItem;
      // this.obj.images = this.objItem.images.map((img) => {
      //   return {
      //     url: img.mediaId ? img.mediaId.url : "",
      //     _id: img._id,
      //     mediaId: img.mediaId ? img.mediaId._id : ""
      //   };
      // });
      this.$refs.gMapAutocomplete.$el.value = this.obj.mainLocation;
      // set stats
      this.stats = this.objItem.stats.map((stat) => {
        if (stat.unit) {
          return {
            statType: stat.statType,
            value: stat.value,
            unit: stat.unit,
            selections: [],
            name: stat.statType.name,
            checked: true,
            inputType: "1"
          };
        } else {
          return {
            statType: stat.statType,
            value: stat.value,
            unit: null,
            selections: stat.statType.selections,
            name: stat.statType.name,
            checked: true,
            inputType: "0"
          };
        }
      });
      this.stats.forEach((stat) => {
        this.stateChange(stat);
      });
    },
    setPlace() {
      this.obj.mainLocation = this.$refs.gMapAutocomplete.$el.value;
    },
    async fetchHosts() {
      let { data } = await this.$axios.get("host/all/new?pageSize=1000");
      if (data) {
        this.hosts = data.data.map((host) => ({
          ...host,
          fullName: `${host.firstName} ${host.lastName}`
        }));
      }
    },
    stateChange(stat) {
      if (stat.checked) {
        let foundStat = this.statInputs.find(
          (statInput) => statInput.name == stat.name
        );
        if (!foundStat) {
          this.statInputs.push(stat);
        }
      } else {
        this.statInputs = this.statInputs.filter(
          (statInput) => statInput.name != stat.name
        );
      }
    },
    handleNext() {
      this.$refs.form.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }
      if (!this.obj.mainLocation || this.obj.mainLocation.length === 0) {
        this.$store.dispatch("showSnack", {
          text: "Main Location is missing.",
          color: `error`
        });
        return;
      }
      // handle stats
      if (this.stats.length > 0) {
        // get only checked stats
        this.obj.stats = [...this.statInputs.filter((stat) => stat.checked)];
        this.obj.stats = [
          ...this.statInputs.map((stat) => {
            if (stat.inputType == "1") {
              return {
                statType: this.objItem
                  ? stat.statType._id
                    ? stat.statType._id
                    : stat.statType
                  : stat._id,
                value: stat.value,
                unit: stat.unit
              };
            } else {
              return {
                statType: this.objItem
                  ? stat.statType._id
                    ? stat.statType._id
                    : stat.statType
                  : stat._id,
                value: stat.value,
                unit: null
              };
            }
          })
        ];
      }

      if (!this.obj.coverImage) {
        this.$store.dispatch("showSnack", {
          text: "cover image is required",
          color: `error`
        });
        return;
      }

      if (!this.obj.images || this.obj.images.length == 0) {
        this.$store.dispatch("showSnack", {
          text: "one image require at least",
          color: `error`
        });
        return;
      }

      if (this.obj.hostId._id || this.obj.hostId._id == "0")
        this.obj.hostId = this.obj.hostId._id;
      if (this.obj.country._id || this.obj.country._id == "0")
        this.obj.country = this.obj.country._id;
      if (this.obj.district._id || this.obj.district._id == "0")
        this.obj.district = this.obj.district._id;
      if (this.obj.season._id || this.obj.season._id == "0")
        this.obj.season = this.obj.season._id;
      if (this.obj.newCategory._id || this.obj.newCategory._id == "0")
        this.obj.newCategory = this.obj.newCategory._id;
      if (this.obj.difficulty._id || this.obj.difficulty._id == "0")
        this.obj.difficulty = this.obj.difficulty._id;

      // this.obj.tag= !this.obj.tag ? 0 : 1;
      this.$emit(
        "dayValue",
        this.obj.dayOrHour == 1 || this.obj.dayOrHour._id == 1
          ? 1
          : this.obj.duration
      );
      this.$emit("nextHandler", this.obj);
    }
  },
  async created() {
    this.language = this.$root.language;
    this.obj.country = this.$root.country.short;
    this.countries = this.$root.countries;
    await this.fetchHosts();
    await this.fetchCategories();
    if (this.objItem && this.objItem._id) {
      this.fetchItemByID();
      await this.fetchStats({ _id: this.objItem.newCategory });
    }
  }
};
</script>
<style lang="scss">
.place-input {
  height: 36px;
  line-height: 35px;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
