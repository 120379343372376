<template>
  <div class="white pa-5 py-3">
    <main-section
      v-if="step == 1"
      key="1"
      @nextHandler="stepOneHandler"
      :objItem="obj"
      @dayValue="dayValueHandler"
      @cancelHandler="cancelHandler"
    ></main-section>
    <steps-section
      v-if="step == 2"
      key="2"
      @nextHandler="stepTwoHandler"
      :objItem="obj"
      :days="dayValue"
      :daysChanged="daysChanged"
      @cancelHandler="step = 1"
    ></steps-section>
    <time-section
      v-if="step == 3"
      key="3"
      :step="step"
      @submitHandler="submitHandler"
      :objItem="obj"
      @cancelHandler="step = 2"
    ></time-section>
  </div>
</template>

<script>
import MainSection from "./components/Main";
import StepsSection from "./components/Steps";
import TimeSection from "./components/Time";
export default {
  components: {
    MainSection,
    StepsSection,
    TimeSection
  },
  props: {
    itemID: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    step: 0,
    obj: undefined,
    dayValue: {},
    bring: "",
    wear: "",
    expect: "",
    daysChanged: false,
    daysDuration: 0
  }),
  watch: {
    $route: {
      handler() {
        this.step = 1;
      }
    }
  },
  methods: {
    cancelHandler() {
      this.$emit("close");
    },
    dayValueHandler(val) {
      this.dayValue = [];
      if (val > 1 && val != this.daysDuration) {
        this.daysChanged = true;
      }
      for (let index = 0; index < val; index++) {
        this.dayValue.push({});
      }
    },
    stepOneHandler(val) {
      this.step = 2;
      this.obj = { ...val };
      this.obj.newSchedules = val.newSchedules;
    },
    async stepTwoHandler(val) {
      this.step = 3;
      this.obj = { ...this.obj, ...val };
      this.obj.newSchedules = val.newSchedules;
    },
    async submitHandler(val) {
      this.obj = { ...this.obj, ...val };
      let scheduleImages = [];
      this.obj.newSchedules.forEach((day, day_index) => {
        day.day.schedule.forEach((schedul, sch_index) => {
          if (schedul.image && schedul.image.length > 0) {
            schedul.image.forEach((img) => {
              if (this.paramID) {
                scheduleImages.push({
                  //  activityId
                  day_index: day_index,
                  scheduleId: schedul._id,
                  media: img
                });
              } else {
                scheduleImages.push({
                  //  activityId
                  day_index: day_index,
                  scheduleId: sch_index,
                  media: img
                });
              }
            });

            if (!this.paramID) {
              schedul.image = [];
            } else {
              schedul.image = schedul.image.filter((img) => img._id);
            }
          } else {
            schedul.image = [];
          }
        });
      });

      if (!this.paramID) {
        let reqObj = { ...this.obj };
        if (reqObj.frequencyType == 1) {
          reqObj.excludedDays = [];
        }
        delete reqObj.coverImage;
        delete reqObj.images;

        // request to send infos to activity
        let resCreate = await this.$axios.post(`host/activity/add/new`, reqObj);

        if (resCreate.data) {
          // add schedules images ids
          resCreate.data.data.newSchedules.forEach((day, idx) => {
            scheduleImages.forEach((sch) => {
              if (sch.day_index == idx) {
                sch.dayId = day._id;
                day.day.schedule.forEach((schData, schedule_index) => {
                  if (sch.scheduleId == schedule_index) {
                    sch.scheduleId = schData._id;
                  }
                });
              }
            });
          });

          // upload cover image
          const callback_OriginalCoverImage = () => {
            return new Promise((resolve) => {
              let formDataMedia = new FormData();
              formDataMedia.append("activityId", resCreate.data.data._id);
              formDataMedia.append("coverImage", this.obj.coverImage);
              this.$axios
                .post(`media/activity/cover`, formDataMedia, {
                  "content-type": "multipart/form-data"
                })
                .then((res) => {
                  if (res.data) {
                    resolve();
                  }
                });
            });
          };

          callback_OriginalCoverImage().then(() => {
            const callback_OriginalImages = async () => {
              return new Promise((resolve) => {
                this.obj.images.forEach(async (img, idx) => {
                  let formDataMedia = new FormData();
                  formDataMedia.append("activityId", resCreate.data.data._id);
                  formDataMedia.append("media", img.file);
                  // request to upload media
                  await this.$axios.post(`media/activity`, formDataMedia, {
                    "content-type": "multipart/form-data"
                  });
                  if (idx == this.obj.images.length - 1) {
                    resolve();
                  }
                });
              });
            };

            callback_OriginalImages().then(() => {
              const callback_OriginalStepsImages = async () => {
                return new Promise((resolve) => {
                  if (scheduleImages.length > 0) {
                    scheduleImages.forEach(async (schedule, idx) => {
                      let formDataMedia = new FormData();

                      formDataMedia.append(
                        "activityId",
                        resCreate.data.data._id
                      );
                      formDataMedia.append("dayId", schedule.dayId);
                      formDataMedia.append("scheduleId", schedule.scheduleId);
                      formDataMedia.append("media", schedule.media);
                      // request to upload media
                      await this.$axios.post(
                        `media/activity/schedule`,
                        formDataMedia,
                        {
                          "content-type": "multipart/form-data"
                        }
                      );

                      if (idx == scheduleImages.length - 1) {
                        resolve();
                      }
                    });
                  } else {
                    resolve();
                  }
                });
              };

              callback_OriginalStepsImages().then(() => {
                this.$store.dispatch("showSnack", {
                  text: "created succesfully",
                  color: `success`
                });
                this.$emit("close");
                this.$emit("reloadData");
              });
            });
          });
        }
      } else {
        let reqImages = this.obj.images.filter((img) => !img._id);
        this.obj.images = this.obj.images.filter((img) => img._id);

        let newCoverImageAdded = false;

        if (!this.obj.coverImage._id) {
          newCoverImageAdded = this.obj.coverImage;
          delete this.obj.coverImage;
        }
        // request to send infos to activity
        let { data } = await this.$axios.put(
          `host/activity/${this.paramID}`,
          this.obj
        );
        if (data) {
          data.data.newSchedules.forEach((day, idx) => {
            scheduleImages.forEach((sch) => {
              if (sch.day_index == idx) {
                sch.dayId = day._id;
              }
            });
          });

          const callback_OriginalCoverImage = () => {
            return new Promise((resolve) => {
              if (newCoverImageAdded) {
                let formDataMedia = new FormData();
                formDataMedia.append("activityId", this.paramID);
                formDataMedia.append("coverImage", newCoverImageAdded);
                this.$axios
                  .post(`media/activity/cover`, formDataMedia, {
                    "content-type": "multipart/form-data"
                  })
                  .then((res) => {
                    if (res.data) {
                      resolve();
                    }
                  });
              } else {
                resolve();
              }
            });
          };
          callback_OriginalCoverImage().then(() => {
            const callback_OriginalImages = async () => {
              return new Promise((resolve) => {
                if (reqImages.length > 0) {
                  reqImages.forEach(async (img, idx) => {
                    let formDataMedia = new FormData();
                    formDataMedia.append("activityId", this.paramID);
                    formDataMedia.append("media", img.file);
                    // request to upload media
                    await this.$axios.post(`media/activity`, formDataMedia, {
                      "content-type": "multipart/form-data"
                    });
                    if (idx == reqImages.length - 1) {
                      resolve();
                    }
                  });
                } else {
                  resolve();
                }
              });
            };
            callback_OriginalImages().then(() => {
              const callback_OriginalStepsImages = async () => {
                return new Promise((resolve) => {
                  if (scheduleImages.length > 0) {
                    scheduleImages.forEach(async (schedule, idx) => {
                      if (!schedule.media._id) {
                        let formDataMedia = new FormData();

                        formDataMedia.append("activityId", this.paramID);
                        formDataMedia.append("dayId", schedule.dayId);
                        formDataMedia.append("scheduleId", schedule.scheduleId);
                        formDataMedia.append("media", schedule.media);
                        // request to upload media
                        await this.$axios.post(
                          `media/activity/schedule`,
                          formDataMedia,
                          {
                            "content-type": "multipart/form-data"
                          }
                        );
                      }
                      if (idx == scheduleImages.length - 1) {
                        resolve();
                      }
                    });
                  } else {
                    resolve();
                  }
                });
              };

              callback_OriginalStepsImages().then(() => {
                this.$store.dispatch("showSnack", {
                  text: "edited succesfully",
                  color: `success`
                });
                this.$emit("close");
                this.$emit("reloadData");
              });
            });
          });
        }
      }
    },
    async fetchItemById() {
      this.paramID = this.itemID;
      if (this.paramID) {
        let { data } = await this.$axios.get(`/activity/${this.paramID}`);
        
        if (data) {
          this.obj = data.data;
          this.daysDuration = data.data.duration;
        }
      }
    }
  },
  async created() {
    this.step = 1;
    await this.fetchItemById();
  }
};
</script>
