<template>
  <div>
    <v-row>
      <!-- FREQUENCY -->
      <v-col cols="12" class="py-1">
        <span class="grey--text font-12 font-500 d-block">FREQUENCY</span>
        <base-select
          :items="frequencies"
          v-model="frequency"
          itemText="name"
          itemValue="_id"
          placeholder="Select"
          @input="frquencyHandler"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-select>
      </v-col>
      <v-col cols="12">
        <!-- selected days -->
        <div class="d-flex align-center mb-4" v-if="frequencyType == 2">
          <div
            class="day-box py-2 px-3 mr-2 radius-5 cursor-pointer"
            :class="day.selected ? ' day-box--active ' : ''"
            v-for="(day, i) in days"
            :key="i"
            @click="dayClicked(day)"
          >
            <span class="font-14">{{ day.title }}</span>
          </div>
        </div>
        <!-- dates -->
        <div v-if="frequencyType || frequencyType == 0">
          <div class="d-flex align-center">
            <span class="grey--text font-12 font-500 d-block mr-2"
              >Select
              {{ frequencyType == 0 || frequencyType == 2 ? "unavailable" : "" }}
              dates</span
            >
            <base-date
              @valueHandler="dataHandler"
              :key="frequencyChanged"
              :multiple="true"
            ></base-date>
          </div>

          <v-row class="mt-4">
            <v-col cols="6" v-for="(selectDate, i) in dates" :key="i">
              <v-row>
                <v-col cols="2" class="py-1">
                  <div class="base-btn" @click="removeDateHandler(i)">
                    <v-icon color="error">mdi-minus-circle-outline</v-icon>
                  </div>
                </v-col>
                <v-col cols="10" class="py-1">
                  <base-input
                    v-if="selectDate"
                    :readOnly="true"
                    :value="selectDate.slice(0, 10)"
                  ></base-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    language: {
      type: String,
      default: "en"
    }
  },
  data: () => ({
    frequencies: [
      { name: "Everyday", _id: 0 },
      { name: "Selected days", _id: 1 },
      { name: "Repeats per week", _id: 2 },
    ],
    frequency: null,
    frequencyType: null,
    frequencyChanged: false,
    dates: [],
    days: [
      { title: "M", id: 0, selected: false },
      { title: "T", id: 1, selected: false },
      { title: "W", id: 2, selected: false },
      { title: "T", id: 3, selected: false },
      { title: "F", id: 4, selected: false },
      { title: "S", id: 5, selected: false },
      { title: "S", id: 6, selected: false },
    ],
  }),
  methods: {
    dataHandler(event) {
      this.dates = [];
      event.forEach((date) => {
        if (date) {
          this.dates.push(date);
        }
      });
      this.$emit("datesChanged", {
        dates: this.dates,
        frequencyType: this.frequency,
      });
    },
    frquencyHandler(val) {
      this.frequencyType = val._id
      this.frequency = val
      this.dates = [];
      this.frequencyChanged = !this.frequencyChanged;
      this.$emit("frequencyChanged", this.frequency);
    },
    removeDateHandler(i) {
      this.dates = [...this.dates.filter((_, idx) => idx != i)];
      this.$emit("datesChanged", {
        dates: this.dates,
        frequencyType: this.frequency,
      });
    },
    dayClicked(day) {
      this.days.forEach((d) => {
        if (day.id == d.id) {
          d.selected = !d.selected;
        }
      });
      let selectedDays = this.days.filter((d) => d.selected);
      selectedDays = selectedDays.map((d) => d.id);
      this.$emit("daysChanged", {
        days: selectedDays,
        frequencyType: this.frequency,
      });
    },
  },
  created() {
    if (this.data) {
      this.frequency = this.data.frequencyType;
      this.frequencyType = this.data.frequencyType;
      this.dates = this.data.excluedDates;
      this.data.excludedDays.forEach((exDay) => {
        this.days.forEach((day) => {
          if (exDay == day.id) {
            day.selected = true;
          }
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.day-box {
  transition: 0.3s;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  span {
    transition: 0.3s;
  }

  &--active {
    background: $secondary;
    span {
      color: white;
    }
  }
}
</style>
