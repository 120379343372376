<template>
  <div>
    <base-select
        v-model="language"
        :items="$root.languages"
        placeholder="Language"
        :returnObject="false"
      ></base-select>
    <v-form v-model="valid" ref="timeForm"> </v-form>
    <v-row>
      <!-- line -->
      <v-col cols="12">
        <div class="b-bottom my-1"></div>
      </v-col>

      <v-col cols="12">
        <time-frequncy
          :key="step"
          @frequencyChanged="frequencyHandler"
          @daysChanged="daysChanged"
          @datesChanged="datesChanged"
          :data="obj.frequency"
          :language="language"
        ></time-frequncy>
      </v-col>
    </v-row>

    <!-- line -->
    <v-row>
      <v-col cols="12">
        <div class="b-bottom my-1"></div>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center">
          <v-checkbox
            label="Featured Date"
            v-model="isFeaturedDate"
            hide-details
          ></v-checkbox>
          <base-date
            class="mx-2"
            v-if="isFeaturedDate"
            @valueHandler="dataHandler"
            v-model="obj.featuredDate"
          ></base-date>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="b-bottom my-1"></div>
      </v-col>
    </v-row>
    <!-- **** Budget & Extras ****  -->
    <v-row>
      <v-col cols="12">
        <span class="font-26 font-500 d-block">Budget & Extras</span>
      </v-col>

      <!-- person or group -->
      <v-col cols="12" class="py-1">
        <span class="grey--text font-12 font-500 d-block">Pricing per</span>
        <base-select
          :items="groups"
          v-model="obj.newPricing.groupOrPerson"
          itemText="name"
          itemValue="_id"
          placeholder="Person or Group"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-select>
      </v-col>

      <!-- start price -->
      <v-col cols="12" md="6" class="py-1">
        <label class="grey--text font-12 font-500">
          Starting price (IN USD)
        </label>
        <base-input
          placeholder="Starting price"
          type="number"
          v-model="obj.newPricing.startPriceInUsd"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-input>
      </v-col>

      <!-- Up to (N of people/group) -->
      <v-col cols="12" md="6" class="py-1">
        <label class="grey--text font-12 font-500"
          >Up to (N of people/group)
        </label>
        <base-input
          placeholder="Number"
          type="number"
          v-model="obj.newPricing.maxNumOfPersons"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-input>
      </v-col>

      <!-- person or group -->
      <v-col cols="12" class="py-1">
        <span class="grey--text font-12 font-500 d-block"
          >Extra pricing per</span
        >
        <base-select
          :items="groups"
          v-model="obj.newPricing.extra"
          itemText="name"
          itemValue="_id"
          placeholder="Person or group?"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-select>
      </v-col>
      <!-- Up to (N of people/group) -->
      <v-col cols="12" md="6" class="py-1">
        <label class="grey--text font-12 font-500">Price per extra </label>
        <base-input
          placeholder="Price per extra"
          type="number"
          v-model="obj.newPricing.pricePerExtra"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-input>
      </v-col>

      <!-- Up to (N of people/group) -->
      <v-col cols="12" md="6" class="py-1">
        <label class="grey--text font-12 font-500">Up to (Max N) </label>
        <base-input
          placeholder="Max number"
          type="number"
          v-model="obj.newPricing.maxNumForExtras"
          :rules="[(v) => !!v || v >= 0 || '']"
        ></base-input>
      </v-col>

      <!-- services -->
      <v-col cols="12" class="px-6">
        <div class="mt-4" v-for="(type, i) in serviceTypes" :key="i">
          <span class="grey--text font-12 font-500 d-block mb-2"
            >{{ type.type }} Services</span
          >
          <v-row v-for="(service, j) in type.data" :key="j">
            <!-- delete -->
            <v-col cols="1">
              <div class="base-btn" @click="removeOption(j, type.type)">
                <v-icon color="error">mdi-minus-circle-outline</v-icon>
              </div>
            </v-col>

            <!-- text -->
            <v-col cols="12" :md="type.type == 'extra' ? 5 : 11">
              <base-input
                placeholder="Add a short headline"
                :rules="[]"
                v-model="service.description[language]"
              ></base-input>
            </v-col>
            <v-col cols="12" md="3" v-if="type.type == 'extra'">
              <base-select
                :items="groups"
                v-model="service.priceType"
                itemText="name"
                itemValue="_id"
                placeholder="Pricing per"
                :rules="[(v) => !!v || v >= 0 || '']"
              ></base-select
            ></v-col>
            <v-col cols="12" md="3" v-if="type.type == 'extra'">
              <base-input
                placeholder="Price"
                type="number"
                v-model="service.price"
                :rules="[(v) => !!v || v >= 0 || '']"
              ></base-input
            ></v-col>
          </v-row>

          <!-- add new -->
          <div class="py-1 mt-2">
            <div class="base-btn" @click="addOption(type.type)">
              <v-icon color="grey">mdi-plus-circle-outline</v-icon>
            </div>
          </div>
        </div>
      </v-col>

      <!-- buttons -->
      <v-col cols="12" class="mt-14">
        <buttons-experience
          @cancel="$emit('cancelHandler')"
          text="Submit"
          @next="submitForm"
          cancelText="Back"
        ></buttons-experience>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
import TimeFrequncy from "./times/Frequncy";
export default {
  props: {
    step: {
      type: Number,
      default: 0
    },
    objItem: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ButtonsExperience,
    TimeFrequncy
  },
  data: () => ({
    groups: [
      { name: "Person", _id: 1 },
      { name: "Group", _id: 0 }
    ],
    valid: false,
    obj: {
      date: [],
      newPricing: {},
      options: [{}],
      featuredDate: null
    },
    serviceTypes: [
      { type: "included", data: [{ description: {} }] },
      { type: "extra", data: [{ description: {}, price: "", priceType: "" }] }
    ],
    isFeaturedDate: false,
    language: "en"
  }),
  methods: {
    dataHandler(event) {
      this.obj.featuredDate = event;
    },
    addOption(type) {
      if (type == "included") {
        this.serviceTypes[0].data.push({ description: "" });
      } else {
        this.serviceTypes[1].data.push({
          description: "",
          price: "",
          pricingBer: ""
        });
      }
    },
    removeOption(j, type) {
      if (type == "included") {
        this.serviceTypes[0].data = this.serviceTypes[0].data.filter(
          (_, idx) => idx != j
        );
      } else {
        this.serviceTypes[1].data = this.serviceTypes[1].data.filter(
          (_, idx) => idx != j
        );
      }
    },

    frequencyHandler(val) {
      this.obj.frequencyType = val;
      this.obj.excluedDates = [];
      this.obj.excludedDays = [];
    },
    datesChanged(val) {
      this.obj.frequencyType = val.frequencyType;
      if (this.$route.params.id) {
        this.obj.excluedDates = val.dates.map((date) => {
          return new Date(date).toISOString();
        });
      } else {
        this.obj.excluedDates = val.dates.map((date) => {
          return new Date(date).toISOString();
        });
      }
    },
    daysChanged(val) {
      this.obj.excludedDays = val.days;
      this.obj.frequencyType = val.frequencyType;
    },
    async submitForm() {
      this.$refs.timeForm.validate();
      if (!this.valid) {
        setTimeout(() => {
          document.querySelector(".v-input.error--text:first-of-type");
        }, 100);
        return;
      }

      // if (!this.paramID) {
      //   // handle options
      this.obj.options = [];
      this.serviceTypes.forEach((type) => {
        if (type.type == "included") {
          this.serviceTypes[0].data.forEach((option) => {
            this.obj.options.push({
              description: option.description,
              included: true
            });
          });
        } else {
          this.serviceTypes[1].data.forEach((option) => {
            this.obj.options.push({
              description: option.description,
              price: +option.price,
              priceType: +option.priceType
            });
          });
        }
      });

      if (!this.obj.excluedDates && this.obj.frequency) {
        this.obj.excluedDates = this.obj.frequency.excluedDates;
      }
      if (!this.obj.excludedDays && this.obj.frequencyType == 2) {
        this.obj.excludedDays = this.obj.frequency.excludedDays;
      }

      if (
        this.obj.frequencyType &&
        (this.obj.frequencyType._id || this.obj.frequencyType._id == "0")
      ) {
        this.obj.frequencyType = this.obj.frequencyType._id;
      }
      if (
        this.obj.dayOrHour &&
        (this.obj.dayOrHour._id || this.obj.dayOrHour._id == "0")
      ) {
        this.obj.dayOrHour = this.obj.dayOrHour._id;
      }
      if (
        this.obj.newPricing.extra &&
        (this.obj.newPricing.extra._id || this.obj.newPricing.extra._id == "0")
      ) {
        this.obj.newPricing.extra = this.obj.newPricing.extra._id;
      }
      if (
        this.obj.newPricing.groupOrPerson &&
        (this.obj.newPricing.groupOrPerson._id ||
          this.obj.newPricing.groupOrPerson._id == "0")
      ) {
        this.obj.newPricing.groupOrPerson =
          this.obj.newPricing.groupOrPerson._id;
      }

      if (!this.isFeaturedDate) {
        this.obj.featuredDate = null;
      }

      this.$emit("submitHandler", this.obj);
    },
    fetchItemByID() {
      this.obj = this.objItem;
      this.serviceTypes[0].data = this.objItem.options.filter(
        (option) => option.included
      );
      this.serviceTypes[1].data = this.objItem.options.filter(
        (option) => !option.included
      );
      if (this.objItem.featuredDate) {
        this.isFeaturedDate = true;
        this.obj.featuredDate = this.objItem.featuredDate;
      }
    }
  },
  created() {
    this.language = this.$root.language;
    if (this.objItem && this.objItem._id) {
      this.fetchItemByID();
    }
  }
};
</script>
